var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container my-24 my-lg-32"},[_vm._m(0),_c('section',{staticClass:"row mt-8"},[_c('div',{staticClass:"col"},[_c('v-card',{staticClass:"py-32 px-16 p-xl-32"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6 col-md-auto pr-md-40"},[_vm._v(" Ужасно! ")]),_c('div',{staticClass:"col-6 col-md-auto order-md-3 pl-md-40 text-right"},[_vm._v(" Все супер! ")]),_c('div',{staticClass:"col-12 col-md"},[_c('v-slider',{attrs:{"variants":_vm.variant,"colors":[
                '#D21212',
                '#D21212',
                '#F88843',
                '#F8A543',
                '#F8F143',
                '#BFF943',
                '#7DF843',
                '#43F855',
                '#39EA40',
                '#33DE3B',
                '#04B420',
              ]}})],1)]),_c('div',{staticClass:"row align-items-center mt-40"},[_c('div',{staticClass:"col-6 col-md-auto pr-md-40"},[_vm._v(" Ужасно! ")]),_c('div',{staticClass:"col-6 col-md-auto order-md-3 pl-md-40 text-right"},[_vm._v(" Все супер! ")]),_c('div',{staticClass:"col-12 col-md"},[_c('v-slider',{attrs:{"variants":_vm.variant}})],1)]),_c('div',{staticClass:"row mt-40"},[_c('div',{staticClass:"col"},[_c('v-button',[_vm._v("Ответить")])],1)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('header',{staticClass:"col"},[_c('b',[_vm._v("Страница 1 из 15")])])])
}]

export { render, staticRenderFns }