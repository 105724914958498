<template>
  <section class="container my-24 my-lg-32">
    <div class="row">
      <header class="col">
        <b>Страница 1 из 15</b>
      </header>
    </div>

    <section class="row mt-8">
      <div class="col">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row align-items-center">
            <div class="col-6 col-md-auto pr-md-40">
              Ужасно!
            </div>

            <div class="col-6 col-md-auto order-md-3 pl-md-40 text-right">
              Все супер!
            </div>

            <div class="col-12 col-md">
              <v-slider
                :variants="variant"
                :colors="[
                  '#D21212',
                  '#D21212',
                  '#F88843',
                  '#F8A543',
                  '#F8F143',
                  '#BFF943',
                  '#7DF843',
                  '#43F855',
                  '#39EA40',
                  '#33DE3B',
                  '#04B420',
                ]"
              />
            </div>
          </div>

          <div class="row align-items-center mt-40">
            <div class="col-6 col-md-auto pr-md-40">
              Ужасно!
            </div>

            <div class="col-6 col-md-auto order-md-3 pl-md-40 text-right">
              Все супер!
            </div>

            <div class="col-12 col-md">
              <v-slider :variants="variant"/>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button>Ответить</v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>
  </section>
</template>

<script>
import VCard from '@components/base/VCard'
import VButton from '@components/base/VButton'
import VSlider from '@components/base/VSlider'

export default {
  name: 'S360Slider',

  components: {
    VSlider,
    VButton,
    VCard
  },

  data: () => ({
    variant: [
      {
        attribute: '',
        group: '',
        id: '0',
        media: '',
        sort: '',
        label: 'Описание 0',
        text: '0'
      },
      {
        attribute: '',
        group: '',
        id: '1',
        media: '',
        sort: '',
        label: 'Описание 1',
        text: '1'
      },
      {
        attribute: '',
        group: '',
        id: '2',
        media: '',
        sort: '',
        label: 'Описание 2',
        text: '2'
      },
      {
        attribute: '',
        group: '',
        id: '3',
        media: '',
        sort: '',
        label: 'Описание 3',
        text: '3'
      },
      {
        attribute: '',
        group: '',
        id: '4',
        media: '',
        sort: '',
        label: 'Описание 4',
        text: '4'
      },
      {
        attribute: '',
        group: '',
        id: '5',
        media: '',
        sort: '',
        label: 'Описание 5',
        text: '5'
      },
      {
        attribute: '',
        group: '',
        id: '6',
        media: '',
        sort: '',
        label: 'Описание 6',
        text: '6'
      },
      {
        attribute: '',
        group: '',
        id: '7',
        media: '',
        sort: '',
        label: 'Описание 7',
        text: '7'
      },
      {
        attribute: '',
        group: '',
        id: '8',
        media: '',
        sort: '',
        label: 'Описание 8',
        text: '8'
      },
      {
        attribute: '',
        group: '',
        id: '9',
        media: '',
        sort: '',
        label: 'Описание 9',
        text: '9'
      },
      {
        attribute: '',
        group: '',
        id: '10',
        media: '',
        sort: '',
        label: 'Описание 10',
        text: '10'
      }
    ]
  })
}
</script>
